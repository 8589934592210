import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AuthenticationService } from '@core/authentication';
import { getPreferredLanguage, makeSelectableLanguage } from '@core/evd/utils';
import { Brand } from '@design-system';
import { environment } from '@env/evd/environment';
import { MESSAGES } from '@i18n/evd';
import { Loading, LoadingComponent, RouteLoading } from '@layout/loading';
import {
  CurrentLocation,
  TopBarComponent,
  TopBarMessages,
} from '@layout/top-bar';
import { LanguageCode, Nil } from '@model';
import { Icon, IconComponent, IconSize } from '@ui/icon';
import { LanguageService } from '@ui/language';
import { SelectableItem } from '@ui/selectable-item';
import {
  SideNavConfig,
  SideNavGroup,
  SideNavItem,
  SideNavVariant,
  SidenavComponent,
} from '@ui/sidenav';
import { UiService } from '@ui/ui.service';
import { isNil } from 'lodash-es';
import { NgLetModule } from 'ng-let';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { LayoutService } from '../../store/layout';
import { AppShellService } from './app-shell.service';

@Component({
  selector: 'app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SidenavComponent,
    IconComponent,
    TopBarComponent,
    LoadingComponent,
    NgLetModule,
  ],
  providers: [AppShellService],
})
export class AppShellComponent implements OnInit {
  public constructor(
    private auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieService,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private service: AppShellService,
    private uiService: UiService,
  ) {}

  @Input() public sideNavConfig: SideNavConfig | Nil;
  @Input() public brand: Brand | Nil;

  private fontLoadingSubject$ = new BehaviorSubject<boolean>(true);

  public currentUser$ = this.service.currentUser$;
  public loading$ = combineLatest([
    this.service.loading$,
    this.fontLoadingSubject$,
  ]).pipe(
    map(([loading, fontLoading]) => {
      if (fontLoading) {
        return Loading.App;
      }
      if (loading) {
        return Loading.Other;
      }
      return undefined;
    }),
  );
  public routeLoading$ = this.service.routeLoading$;

  public sideNavOpened = true;
  public sideNavVariant: SideNavVariant = 'persistent';

  public version = environment.version;

  public versionTimestampDate = new Date(environment.versionTimestamp);
  public versionTimestamp = `${this.versionTimestampDate.toLocaleTimeString()} ${this.versionTimestampDate.toLocaleDateString()}`;

  public eatonIcon: Icon = {
    name: 'blui-eaton',
    size: IconSize.XXlarge,
  };

  public location$ = this.service.location$;

  public activeItemId$ = this.layoutService.activeNavItemId$;

  public topBarMessages: TopBarMessages = MESSAGES.app.account;

  public languages: SelectableItem[] = environment.supportedLanguages.map(
    makeSelectableLanguage,
  );

  public currentLanguage: SelectableItem = makeSelectableLanguage(
    getPreferredLanguage(),
  );

  public ngOnInit(): void {
    combineLatest([this.uiService.smallScreen$, this.location$])
      .pipe(
        tap(([state, location]) => {
          this.updateSideNavState(state, location);
        }),
      )
      .subscribe();

    // we must wait until the material icons font is loaded to avoid
    // displaying unloaded (text) icons.
    if (isNil(document.fonts)) {
      this.fontLoadingSubject$.next(false);
    } else {
      document.fonts.ready.then(() => {
        setTimeout(() => {
          this.fontLoadingSubject$.next(false);
        }, 500);
      });
    }

    // when the app starts we must trigger detect changes as soon
    // as loading is null to display the page faster
    // This is due to the code above where we wait for the fonts
    this.loading$
      .pipe(
        filter(isNil),
        take(1),
        tap(() => {
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  public onSideNavToggle(): void {
    this.sideNavOpened = !this.sideNavOpened;
  }

  public onSideNavItemSelect(_item: SideNavItem | SideNavGroup): void {
    this.layoutService.setRouteLoading(RouteLoading.Page);
    this.closeSideNavIfTemporary();
  }

  public onLogout(): void {
    this.auth.logoff();
  }

  public onPasswordEdit(): void {
    window.open(
      `${environment.openIdConfiguration.authority}/Manage/ChangePassword`,
      '_blank',
    );
  }

  public onLanguageChange(language: LanguageCode): void {
    this.cookieService.set('preferedLanguage', String(language), {
      path: '/',
    });
    this.currentLanguage = makeSelectableLanguage(language);
    window.location.reload();
  }

  public onHomeClick(): void {
    this.service.navigateToHome();
  }

  private closeSideNavIfTemporary(): void {
    if (this.sideNavOpened && this.sideNavVariant === 'temporary') {
      this.sideNavOpened = false;
    }
  }

  private updateSideNavState(
    isSmallScreen: boolean,
    location: CurrentLocation | Nil,
  ): void {
    if (isNil(location)) {
      this.sideNavOpened = false;
      this.sideNavVariant = 'temporary';
    } else {
      this.sideNavOpened = !isSmallScreen;
      this.sideNavVariant = isSmallScreen ? 'temporary' : 'persistent';
    }
    this.cdr.detectChanges();
  }
}
