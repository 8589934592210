import { ErrorHandler, Injectable } from '@angular/core';
import { isMaintenanceError, isUnauthorizedError } from '@core/error';
import { createErrorHandler } from '@sentry/angular-ivy';
import { ZodError } from 'zod';

import { NavigationService } from './services';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public constructor(private navigationService: NavigationService) {}

  private sentry = createErrorHandler();

  public handleError(error: any) {
    this.sentry.handleError(error);

    if (error instanceof ZodError) {
      console.error(error.name, error.message);
    }

    if (isMaintenanceError(error)) {
      this.navigationService.navigateToMaintenancePage();
    }

    if (isUnauthorizedError(error)) {
      this.navigationService.navigateToUnauthorizedPage();
    }
  }
}
