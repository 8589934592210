@if (opened$ | async) {
  <div class="overlay" click="onOverlayClick()"></div>
  <div #panel class="panel animate__animated" [ngClass]="panelAnimationClass">
    <div class="etn-title">
      <span>{{ title }}</span>
      <mat-chip-list>
        <mat-chip>{{ notifications.length }}</mat-chip>
      </mat-chip-list>
      <etn-action [action]="closePanelAction"></etn-action>
    </div>
    @for (notification of notifications; track notification.id) {
      <div (click)="onClick(notification)" class="notification-container">
        <etn-icon
          [ngClass]="getColorClass(notification)"
          [name]="getIcon(notification)"
        ></etn-icon>

        <div class="notification-details">
          <div>{{ notification.name }}</div>
          <div class="mat-caption etn-disabled-text">
            {{ notification.caption }}
          </div>
        </div>

        <etn-action [action]="getAction(notification)"></etn-action>
      </div>
    }
    @if (notifications.length > 0) {
      <div class="bottom-action">
        <etn-action [action]="removeAllAction"></etn-action>
      </div>
    }
  </div>
}
