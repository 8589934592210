<mat-toolbar color="primary">
  <ng-content select="[etn-left-content]"></ng-content>
  <div #titleContainer class="title-container">
    <blui-three-liner>
      <div
        blui-title
        class="title"
        [ngStyle]="{ 'width.px': titleContainerWidth() }"
      >
        <ng-content select="[etn-title]"></ng-content>
      </div>
      <div
        blui-subtitle
        class="subtitle"
        [ngStyle]="{ 'width.px': titleContainerWidth() }"
      >
        <ng-content select="[etn-subtitle]"></ng-content>
      </div>
      <div
        blui-info
        class="info"
        [ngStyle]="{ 'width.px': titleContainerWidth() }"
      >
        <ng-content select="[etn-info]"></ng-content>
      </div>
    </blui-three-liner>
  </div>

  <div class="right-content">
    <ng-content select="[etn-right-content]"></ng-content>
  </div>

  @if (accountName) {
    <etn-user-menu
      [userName]="accountName"
      [items]="accountActions"
    ></etn-user-menu>
  }
  @if (languages && languages.length > 1) {
    <etn-language-picker
      [currentLanguage]="currentLanguage"
      [languages]="languages"
      (languageChange)="languageChange.emit($event)"
    ></etn-language-picker>
  }
</mat-toolbar>
