<etn-sidenav
  [config]="sideNavConfig"
  [opened]="sideNavOpened"
  [variant]="sideNavVariant"
  [activeItemId]="activeItemId$ | async"
  [brand]="brand"
  (toggle)="onSideNavToggle()"
  (itemSelect)="onSideNavItemSelect($event)"
>
  <div footer class="footer">
    <div>
      <div>v{{ version }}</div>
      <div>{{ versionTimestamp }}</div>
    </div>
    <div class="copyright-container">
      <div class="logo">
        <etn-icon [name]="eatonIcon.name" [size]="eatonIcon.size"></etn-icon>
      </div>
      <div class="copyright">
        <div>Copyright © Eaton</div>
        <div>All Rights Reserved</div>
      </div>
    </div>
  </div>

  <div *ngLet="location$ | async as location" class="app-shell-content">
    @if (location) {
      <etn-top-bar
        [currentLanguage]="currentLanguage"
        [languages]="languages"
        [messages]="topBarMessages"
        [location]="location"
        [user]="currentUser$ | async"
        (logout)="onLogout()"
        (passwordEdit)="onPasswordEdit()"
        (languageChange)="onLanguageChange($event)"
        (menuClick)="onSideNavToggle()"
        (homeClick)="onHomeClick()"
      ></etn-top-bar>
    }
    <div
      class="router-outlet"
      [class.fullscreen]="!location"
      [class.has-breadcrumbs]="
        location && location.breadcrumbs && location.breadcrumbs.length > 0
      "
    >
      <ng-content></ng-content>
    </div>
  </div>
</etn-sidenav>
<etn-loading
  [loading]="loading$ | async"
  [routeLoading]="routeLoading$ | async"
></etn-loading>
