<div
  [matMenuTriggerFor]="menu"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  class="menu-trigger"
>
  <!-- AVATAR VARIANT -->
  @if (variant === avatarVariant) {
    <div class="avatar">
      {{ avatarName }}
    </div>
  } @else {
    <etn-icon [name]="accountIcon" [color]="iconColor"></etn-icon>
    <span class="user-name">{{ userName }}</span>
    <etn-icon [name]="chevronIcon" [color]="iconColor"></etn-icon>
  }

  <!-- FULL VARIANT -->
</div>

<!-- MENU ITEMS -->
<mat-menu #menu="matMenu" class="etn-user-menu-items">
  @if (variant === avatarVariant) {
    <div class="menu-items-header">
      <div class="avatar">{{ avatarName }}</div>
      <div class="user-name">{{ userName }}</div>
    </div>
  }
  @for (item of items; track item.id) {
    <div mat-menu-item>
      <div class="menu-item" (click)="onUserMenuItemClick(item)">
        <etn-icon [name]="item.icon" [color]="item.iconColor"></etn-icon>
        <span>{{ item.name }}</span>
      </div>
    </div>
  }
</mat-menu>
