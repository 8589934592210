import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { Nil } from '@model';
import { isNil } from 'lodash-es';

import { Action, ActionColor, ActionComponent } from '../action';
import { IconComponent, IconName, IconSize } from '../icon';
import { NotificationsPanelModule } from './notifications-panel.module';
import { NotificationsPanelService } from './notifications-panel.service';
import {
  NOTIFICATION_ICONS,
  NOTIIFCATION_COLOR_CLASS,
  Notification,
  NotificationPanelMessages,
} from './notifications-panel.types';

const IN_ANIMATION = 'animate__slideInRight';
const OUT_ANIMATION = 'animate__slideOutRight';

@Component({
  selector: 'etn-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ActionComponent,
    MatChipsModule,
    NotificationsPanelModule,
  ],
})
export class NotificationsPanelComponent {
  public constructor(private service: NotificationsPanelService) {}

  @Input() public notifications: Notification[] = [];
  @Input() public set messages(messages: NotificationPanelMessages | Nil) {
    this.title = messages?.title ?? '';
    this.removeAllAction = this.getRemoveAllAction(messages?.removeAll);
  }

  @Output() public remove = new EventEmitter<Notification>();
  @Output() public removeAll = new EventEmitter<void>();
  @Output() public notificationClick = new EventEmitter<Notification>();

  @ViewChild('panel') public panel: ElementRef | Nil;

  public opened$ = this.service.opened$;
  public panelAnimationClass = IN_ANIMATION;
  public title = '';

  public removeAllAction: Action | Nil;

  public closePanelAction: Action = {
    id: 'close',
    icon: 'clear',
    iconSize: IconSize.Small,
    callback: () => {
      return this.closePanel();
    },
  };

  public getIcon({ type }: Notification): IconName {
    return NOTIFICATION_ICONS[type];
  }

  public getColorClass({ type }: Notification): string {
    return NOTIIFCATION_COLOR_CLASS[type];
  }

  public getAction(notification: Notification): Action {
    return {
      id: notification.id,
      icon: 'delete',
      callback: () => {
        this.remove.emit(notification);
      },
    };
  }

  public onClick(notification: Notification): void {
    if (notification.callback) {
      notification.callback();
    }
    this.notificationClick.emit(notification);
  }

  public onOverlayClick(): void {
    this.closePanel();
  }

  private closePanel(): void {
    this.panel?.nativeElement.addEventListener('animationend', () => {
      this.service.close();
      this.panelAnimationClass = IN_ANIMATION;
    });
    this.panelAnimationClass = OUT_ANIMATION;
  }

  private getRemoveAllAction(name: string | Nil): Action | Nil {
    if (isNil(name)) {
      return undefined;
    }
    return {
      id: 'remove-all',
      name,
      icon: 'delete',
      color: ActionColor.Accent,
      raised: true,
      callback: () => {
        this.removeAll.emit();
      },
    };
  }
}
