import { WithId, WithName } from '@model';
import { IconName } from '@ui/icon';
import { IconColor } from '@ui/icon/icon.types';

export enum UserMenuVariant {
  Avatar = 'avatar',
  Full = 'full',
}

export interface UserMenuItem extends WithId, WithName {
  icon?: IconName;
  iconColor?: IconColor;
  callback?: () => void;
}
