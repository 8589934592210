import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class LogPwaUpdateService {
  public constructor(private updates: SwUpdate) {}

  public initialize(): void {
    this.updates.versionUpdates.subscribe((evt) => {
      // eslint-disable-next-line smells/no-switch
      switch (evt.type) {
        case 'NO_NEW_VERSION_DETECTED':
          console.log(`No new version detection: ${evt.version.hash}`);
          break;
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`,
          );
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`,
          );
          break;
      }
    });
  }
}
