import { WithId, WithName } from '@model';

import { IconName } from '../icon';

export enum NotificationType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warn = 'warn',
}

export const NOTIFICATION_ICONS: Record<NotificationType, IconName> = {
  error: 'cancel',
  info: 'info',
  success: 'check_circle',
  warn: 'error',
};

export const NOTIIFCATION_COLOR_CLASS: Record<NotificationType, string> = {
  error: 'error',
  info: 'charging',
  success: 'success',
  warn: 'warn',
};

export interface Notification extends WithId, WithName {
  type: NotificationType;
  caption: string;
  callback?: () => void;
}

export interface NotificationPanelMessages {
  title: string;
  removeAll: string;
}
