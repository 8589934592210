import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingService } from '@core/tracking';
import { RouteLoading } from '@layout/loading';
import { Nil } from '@model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  routerCancelAction,
  routerNavigatedAction,
  routerNavigationAction,
} from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { capitalize } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { setLoading, setRouteLoading } from '../layout';

@Injectable()
export class StoreRouterEffects {
  public constructor(
    private actions$: Actions,
    private trackingService: TrackingService,
    private router: Router,
  ) {}

  public routerNavigation$ = createEffect(() => {
    return this.routerNavigationEffect();
  });

  public routerCancel$ = createEffect(() => {
    return this.routerCancelEffect();
  });

  public routerNavigated$ = createEffect(() => {
    return this.routerNavigatedEffect();
  });

  private routerNavigationEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      map((action) => {
        return setRouteLoading({
          routeLoading: this.getRouteLoading(
            this.router.url,
            action.payload.event.url,
          ),
        });
      }),
    );
  }

  private routerCancelEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(routerCancelAction),
      map(() => {
        return setRouteLoading({ routeLoading: undefined });
      }),
    );
  }

  private routerNavigatedEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      tap((action) => {
        // reset save trap

        // send tracking event
        const url = action.payload.routerState.url;
        const viewName = this.getViewName(url);
        this.trackingService.trackView(viewName);
      }),
      switchMap(() => {
        return [
          setLoading({ loading: false }),
          setRouteLoading({ routeLoading: undefined }),
        ];
      }),
    );
  }

  /**
   * Turn an url to human readable text
   * for example: 'reset-password/email' will become 'Reset Password > Email'
   *
   * @param viewUrl the url to convert
   */
  private getViewName(viewUrl: string): string {
    return viewUrl
      .replace(/^\/|\/$/g, '') // remove first and last slash
      .split('/')
      .map((item) => {
        return item.split('-').map(capitalize).join(' ');
      })
      .join(' - ');
  }

  private getRouteLoading(
    currentUrl: string,
    targetUrl: string,
  ): RouteLoading | Nil {
    const currentUrlParts = currentUrl.split('/');
    const targetUrlParts = targetUrl.split('/');

    const currentArea =
      currentUrlParts.length > 2
        ? currentUrlParts[currentUrlParts.length - 2]
        : undefined;

    const targetArea =
      targetUrlParts.length > 2
        ? targetUrlParts[targetUrlParts.length - 2]
        : undefined;

    if (currentArea === targetArea) {
      return RouteLoading.Tab;
    } else {
      return RouteLoading.Page;
    }
  }
}
