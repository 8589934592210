import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '@core/authentication';
import { environment } from '@env/evd/environment';
import { LanguageService } from '@ui/language';
import { mergeMap, take } from 'rxjs';

export const apiInterceptor: HttpInterceptorFn = (request, next) => {
  const auth = inject(AuthenticationService);
  const languageService = inject(LanguageService);

  if (request.url.startsWith(environment.urls.api)) {
    return auth.getToken().pipe(
      take(1),
      mergeMap((token) => {
        const headers = request.headers
          .set('Authorization', `Bearer ${token}`)
          .set('LanguageCode', languageService.getLanguage())
          .set('TimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        return next(
          request.clone({
            headers,
          }),
        );
      }),
    );
  }

  return next(request);
};
