<blui-drawer-layout
  [variant]="variant"
  [width]="width"
  [class.empty]="empty"
  (backdropClick)="toggle.emit()"
>
  <blui-drawer blui-drawer [open]="opened" [sideBorder]="false">
    <blui-drawer-header [class.has-content]="hasHeaderContent">
      <div blui-title-content class="header-content">
        <div class="header">
          <etn-action
            blui-icon
            [action]="toggleAction"
            class="toggle-action"
          ></etn-action>
          <etn-logo
            [brand]="brand"
            [type]="logoType"
            [height]="logoHeight"
          ></etn-logo>
          <div class="header-content-title">{{ title }}</div>
        </div>
        @if (opened) {
          <div #header class="header">
            <ng-content select="[header]"></ng-content>
          </div>
        }
      </div>
    </blui-drawer-header>
    <blui-drawer-body>
      <ng-content select="[customTopBodyTemplate]"></ng-content>
      <blui-drawer-nav-group>
        <!-- TOP ITEMS -->
        @for (item of config?.topItems; track item.id) {
          <blui-drawer-nav-item
            [attr.data-test]="'sidenav-' + item.id"
            [title]="item.title"
            [class.blui-drawer-nav-item-active]="activeItemId === item.id"
            [expanded]="item.expanded || false"
            (select)="onSelect(item)"
          >
            <etn-icon blui-icon [name]="item.icon" [size]="iconSize"></etn-icon>
          </blui-drawer-nav-item>
        }

        <!-- GROUPS -->
        @for (group of config?.groups; track group.id) {
          <blui-drawer-nav-item
            [attr.data-test]="'sidenav-' + group.id"
            [class.blui-drawer-nav-item-active]="activeItemId === group.id"
            [divider]="group.divider || false"
            [expanded]="group.expanded || true"
            [title]="group.title || ''"
            (select)="onGroupSelect(group)"
          >
            <etn-icon
              blui-icon
              [name]="group.icon"
              [size]="iconSize"
            ></etn-icon>
            @for (item of group.items; track item.id) {
              <blui-drawer-nav-item
                [attr.data-test]="'sidenav-' + item.id"
                [class.blui-drawer-nav-item-active]="activeItemId === item.id"
                [expanded]="item.expanded || false"
                [title]="item.title"
                (select)="onSelect(item)"
              >
                <etn-icon
                  blui-icon
                  [name]="item.icon"
                  [size]="iconSize"
                ></etn-icon>
              </blui-drawer-nav-item>
            }
          </blui-drawer-nav-item>
        }

        <!-- Settings ITEMS -->
        @for (item of config?.settingItems; track item.id) {
          <blui-drawer-nav-item
            [attr.data-test]="'sidenav-' + item.id"
            [title]="item.title"
            [class.blui-drawer-nav-item-active]="activeItemId === item.id"
            [expanded]="item.expanded || false"
            (select)="onSelect(item)"
          >
            <etn-icon blui-icon [name]="item.icon" [size]="iconSize"></etn-icon>
          </blui-drawer-nav-item>
        }
      </blui-drawer-nav-group>

      <blui-spacer></blui-spacer>
    </blui-drawer-body>
    <blui-drawer-footer
      [divider]="config?.bottomItems === undefined"
      [hideContentOnCollapse]="true"
    >
      <blui-drawer-nav-group>
        <!-- BOTTOM ITEMS -->
        @for (item of config?.bottomItems; track item.id) {
          <blui-drawer-nav-item
            [attr.data-test]="'sidenav-' + item.id"
            [title]="item.title"
            [class.blui-drawer-nav-item-active]="activeItemId === item.id"
            [expanded]="item.expanded || false"
            [divider]="true"
            (select)="onSelect(item)"
          >
            <etn-icon blui-icon [name]="item.icon" [size]="iconSize"></etn-icon>
          </blui-drawer-nav-item>
        }
      </blui-drawer-nav-group>
      <ng-content select="[customFooterTemplate]"></ng-content>
      <ng-content select="[footer]"></ng-content>
    </blui-drawer-footer>
  </blui-drawer>
  <div #content blui-content class="content">
    <ng-content></ng-content>
  </div>
</blui-drawer-layout>
