import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  viewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { DrawerModule } from '@brightlayer-ui/angular-components';
import { isNotNil } from '@core/is-not-nil';
import { Brand, LogoType } from '@design-system';
import { Id, Nil } from '@model';
import { LogoModule } from '@ui/logo';
import { isNil, throttle } from 'lodash-es';

import { Action, ActionColor, ActionComponent } from '../action';
import { IconComponent, IconSize } from '../icon';
import {
  SideNavConfig,
  SideNavGroup,
  SideNavItem,
  SideNavVariant,
} from './sidenav.types';

export const RAIL_LOGO_HEIGHT = 18;
export const DEFAULT_LOGO_HEIGHT = 28;

@Component({
  selector: 'etn-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ActionComponent,
    CommonModule,
    DrawerModule,
    IconComponent,
    LogoModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    RouterModule,
  ],
})
export class SidenavComponent implements OnChanges {
  public customTopBodyTemplate = viewChild.required<
    ElementRef<HTMLElement> | Nil
  >('customTopBodyTemplate');

  public customFooterTemplate = viewChild.required<
    ElementRef<HTMLElement> | Nil
  >('customFooterTemplate');

  public constructor(private router: Router) {}

  @Input() public config: SideNavConfig | Nil;
  @Input() public width = 350;
  @Input() public sideBorder = false;
  @HostBinding('class')
  @Input()
  public variant: SideNavVariant = 'persistent';
  @Input() public opened = true;
  @Input() public title: string | Nil;
  @Input() public brand: Brand | Nil;
  @Input() public activeItemId: Id | Nil;

  @HostBinding('class.transparent-background')
  @Input()
  public transparentBackground = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public toggle = new EventEmitter<void>();
  @Output() public itemSelect = new EventEmitter<SideNavItem | SideNavGroup>();

  @ViewChild('content') public content: ElementRef<HTMLDivElement> | Nil;
  @ViewChild('header') public set header(
    header: ElementRef<HTMLElement> | Nil,
  ) {
    this.hasHeaderContent = (header?.nativeElement.children.length ?? 0) > 0;
  }

  public iconSize = IconSize.Default;

  public logoType = LogoType.Contrast;
  public logoHeight = DEFAULT_LOGO_HEIGHT;

  public toggleAction: Action = {
    id: 'toggle',
    icon: 'menu',
    iconSize: IconSize.Default,
    callback: () => {
      this.toggle.emit();
    },
    color: ActionColor.Primary,
  };

  public empty = true;

  public hasHeaderContent = false;

  public railVariant: SideNavVariant = 'rail';

  // Navigate the user to the item router link url but
  // prevent the user from spamming it.
  private navigate = throttle((item: SideNavItem) => {
    if (isNotNil(item.routerLink)) {
      this.router.navigateByUrl(item.routerLink);
    }
  }, 2000);

  public ngOnChanges({ config, variant }: SimpleChanges): void {
    if (isNotNil(config)) {
      this.empty = this.isEmpty(this.config);
    }

    if (isNotNil(variant)) {
      if (this.variant === 'rail') {
        this.logoType = LogoType.Primay;
        this.logoHeight = RAIL_LOGO_HEIGHT;
      } else {
        this.logoType = LogoType.Contrast;
        this.logoHeight = DEFAULT_LOGO_HEIGHT;
      }
    }
  }

  public onSelect(item: SideNavItem): void {
    if (item.id !== this.activeItemId) {
      if (isNotNil(item.onSelect)) {
        item.onSelect();
      }
      this.itemSelect.emit(item);
    }
    this.navigate(item);
  }

  public onGroupSelect(group: SideNavGroup): void {
    if (isNotNil(group.routerLink) && group.id !== this.activeItemId) {
      this.itemSelect.emit(group);
    }
  }

  private isEmpty(config: SideNavConfig | Nil): boolean {
    if (isNil(config)) {
      return true;
    }
    const groups = config.groups.length;
    const topItems = config.topItems?.length ?? 0;
    const bottomItems = config.bottomItems?.length ?? 0;
    return groups + topItems + bottomItems === 0;
  }
}
