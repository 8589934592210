import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NotificationService } from '@ui/notification';
import { delay, filter, first } from 'rxjs';

@Injectable()
export class PromptPwaUpdateService {
  public constructor(
    private swUpdate: SwUpdate,
    private notificationService: NotificationService,
    private appRef: ApplicationRef,
  ) {}

  private appIsStable$ = this.appRef.isStable.pipe(
    first((isStable) => {
      return isStable === true;
    }),
  );

  public initialize(promptMessage: string, actionMessage: string): void {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => {
          return evt.type === 'VERSION_READY';
        }),
        delay(10000),
      )
      .subscribe(() => {
        this.notificationService.notifyInfo(promptMessage, undefined, {
          timeOut: undefined,
          actions: [
            {
              id: 'refresh',
              name: actionMessage,
              callback: () => {
                document.location.reload();
              },
            },
          ],
        });
      });
  }
}
