import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { filterNil } from '@core/rxjs/filter-nil';
import { TrackingService } from '@core/tracking';
import { Brand, FAVICON_MAPPING } from '@design-system';
import {
  User,
  hasAuthMeansLink,
  hasChargingSessionsLink,
  hasEmspContractsLink,
} from '@model/evd/users';
import {
  CookieConsent,
  CookieConsentComponent,
  CookieConsentMessages,
  CookieConsentService,
} from '@ui/cookie-consent';
import { LanguageModule } from '@ui/language';
import { SaveTrapComponent } from '@ui/save-trap';
import { SideNavConfig, SideNavItem } from '@ui/sidenav';
import { ThemingModule } from '@ui/theming';
import { Observable, filter, map, mergeMap, take, tap } from 'rxjs';

import { MESSAGES } from '../i18n';
import { THEME_MAPPING } from '../themes';
import {
  AUTH_MEANS_NAV_ITEM_ID,
  AUTH_MEANS_URL_SEGMENT,
  CHARGING_SESSIONS_NAV_ITEM_ID,
  CHARGING_SESSIONS_URL_SEGMENT,
  CONTRACTS_NAV_ITEM_ID,
  CONTRACTS_URL_SEGMENT,
  DASHBOARD_NAV_ITEM_ID,
  DASHBOARD_URL_SEGMENT,
  EMSP_URL_SEGMENT,
  REPORTS_NAV_ITEM_ID,
  REPORTS_URL_SEGMENT,
} from './core/const/navigation';
import { AppShellComponent } from './layout/app-shell';
import { CurrentUserService } from './store/current-user';
import { LayoutService } from './store/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AppShellComponent,
    CommonModule,
    CookieConsentComponent,
    HammerModule,
    LanguageModule,
    RouterModule,
    SaveTrapComponent,
    ThemingModule,
  ],
})
export class AppComponent implements OnInit {
  public constructor(
    private cookieConsentService: CookieConsentService,
    private currentUserService: CurrentUserService,
    private layoutService: LayoutService,
    private trackingService: TrackingService,
  ) {}

  public readonly brand: Brand = 'eaton';
  public theme = `${THEME_MAPPING[this.brand]}.css`;
  public favicon = `logos/${this.brand}/${FAVICON_MAPPING[this.brand]}`;

  public loading$ = this.layoutService.loading$;
  public routeLoading$ = this.layoutService.routeLoading$;
  public currentUser$ = this.currentUserService.currentUserFetched$;

  public cookieConsentMessages: CookieConsentMessages =
    MESSAGES.app.cookieConsent;
  public cookieConsents: CookieConsent[] = [
    {
      id: 'analytics',
      name: MESSAGES.app.cookieConsent.analytics,
      accepted: true,
    },
  ];
  public cookiesNotAccepted$ = this.currentUser$.pipe(
    filterNil(),
    mergeMap((currentUser) => {
      return this.cookieConsentService.hasConsentObservable(
        this.cookieConsents,
        currentUser.id,
      );
    }),
    map((accepted) => {
      return !accepted;
    }),
  );
  public analyticsCookiesAccepted$ = this.currentUser$.pipe(
    filterNil(),
    mergeMap((currentUser) => {
      return this.cookieConsentService.getCookieValueObservable(
        this.cookieConsents[0],
        currentUser.id,
      );
    }),
  );

  public sideNavConfig$: Observable<SideNavConfig> = this.currentUser$.pipe(
    filterNil(),
    map((user) => {
      return this.getSidenavConfig(user);
    }),
  );

  public ngOnInit(): void {
    this.analyticsCookiesAccepted$
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => {
          this.trackingService.initialize();
        }),
      )
      .subscribe();
  }

  public onAcceptCookies(consents: CookieConsent[], currentUser: User) {
    this.cookieConsentService.saveConsent(consents, currentUser.id);
  }

  private getSidenavConfig(user: User): SideNavConfig {
    const topItems: SideNavItem[] = [
      {
        id: DASHBOARD_NAV_ITEM_ID,
        icon: 'home',
        title: MESSAGES.appMenu.label.home,
        routerLink: DASHBOARD_URL_SEGMENT,
        items: [],
      },
    ];

    if (hasEmspContractsLink(user)) {
      topItems.push({
        id: CONTRACTS_NAV_ITEM_ID,
        icon: 'card_membership',
        title: MESSAGES.appMenu.label.contracts,
        items: [],
        routerLink: `${EMSP_URL_SEGMENT}/${CONTRACTS_URL_SEGMENT}`,
      });
    }

    if (hasChargingSessionsLink(user)) {
      topItems.push({
        id: CHARGING_SESSIONS_NAV_ITEM_ID,
        title: MESSAGES.appMenu.label.chargingSessions,
        icon: 'ev_station',
        routerLink: CHARGING_SESSIONS_URL_SEGMENT,
        items: [],
      });
    }

    topItems.push({
      id: REPORTS_NAV_ITEM_ID,
      title: MESSAGES.appMenu.label.reports,
      icon: 'description',
      items: [],
      routerLink: `${EMSP_URL_SEGMENT}/${REPORTS_URL_SEGMENT}`,
    });

    if (hasAuthMeansLink(user)) {
      topItems.push({
        id: AUTH_MEANS_NAV_ITEM_ID,
        title: MESSAGES.appMenu.label.authMeans,
        icon: 'card_membership',
        routerLink: AUTH_MEANS_URL_SEGMENT,
        items: [],
      });
    }

    return {
      groups: [],
      topItems,
    };
  }
}
