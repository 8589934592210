import { Injectable } from '@angular/core';
import { NavigationService } from '@core/evd/services';
import { TopBarUser } from '@layout/top-bar';
import { Nil } from '@model';
import { CurrentUserService } from '@store/evd/current-user';
import { LayoutService } from '@store/evd/layout';
import { Observable } from 'rxjs';

@Injectable()
export class AppShellService {
  public constructor(
    private currentUserService: CurrentUserService,
    private layoutService: LayoutService,
    private navigationService: NavigationService,
  ) {}

  public location$ = this.layoutService.getLocation();

  public currentUser$: Observable<TopBarUser | Nil> =
    this.currentUserService.currentUser$;
  public loading$ = this.layoutService.loading$;
  public routeLoading$ = this.layoutService.routeLoading$;

  public navigateToHome() {
    this.navigationService.navigateToDashboard(true);
  }
}
