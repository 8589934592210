<etn-toolbar
  [accountActions]="accountActions"
  [accountName]="accountName"
  [languages]="languages"
  [currentLanguage]="currentLanguage"
  [class.mobile-view]="mobileView()"
  (languageChange)="onLanguageChange($event)"
>
  @if (backAction || smallScreen()) {
    <div etn-left-content>
      @if (backAction) {
        <etn-action [action]="backAction"></etn-action>
      } @else {
        @if (smallScreen() && !hideMenu) {
          <etn-action [action]="menuAction"></etn-action>
        }
      }
    </div>
  }
  <div etn-title>{{ location?.name }}</div>

  <!-- BREADCRUMBS -->
  @if (!mobileView() && location && location.breadcrumbs.length > 0) {
    <etn-top-bar-breadcrumbs
      etn-subtitle
      [location]="location"
      (menuClick)="menuClick.emit()"
    ></etn-top-bar-breadcrumbs>
  }

  <!-- TAGS -->
  @if (location && location.tags && location.tags.length > 0) {
    <div etn-info class="tags">
      @for (tag of location.tags; track tag.name) {
        <etn-list-item-tag
          [label]="tag.name"
          [color]="tag.color"
        ></etn-list-item-tag>
      }
    </div>
  }

  <ng-content etn-right-content></ng-content>
</etn-toolbar>
