import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { AuthMeansApiService } from '@api/evd/auth-means';
import { ChargingSessionsApiService } from '@api/evd/charging-sessions';
import {
  EmspContractsApiService,
  EmspOfferInvitationAcceptanceApiService,
  EmspOfferInvitationApiService,
} from '@api/evd/emsp';
import { UserAddressesApiService, UserApiService } from '@api/evd/users';
import { AuthenticationModule } from '@core/authentication';
import { GlobalErrorHandler } from '@core/evd/globlal-error.handler';
import { apiInterceptor } from '@core/evd/interceptors';
import { LogPwaUpdateService, PromptPwaUpdateService } from '@core/pwa';
import { TrackingModule } from '@core/tracking';
import { WebsocketService } from '@core/websocket';
import { LanguageCode } from '@model';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { TraceService } from '@sentry/angular-ivy';
import {
  CURRENT_USER_REDUCER_STATE_NAME,
  CurrentUserEffects,
  currentUserReducer,
} from '@store/evd/current-user';
import {
  LAYOUT_REDUCER_STATE_NAME,
  LayoutEffects,
  layoutReducer,
} from '@store/evd/layout';
import { StoreRouterEffects } from '@store/evd/router/router.effects';
import { DialogModule } from '@ui/dialog';
import {
  LanguageModule,
  LanguageService,
  MultiLanguageFallback,
} from '@ui/language';
import { NotificationService } from '@ui/notification';
import { NotificationComponent } from '@ui/notification/notification.component';
import { provideSaveTrap } from '@ui/save-trap';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { MESSAGES, lang, localeData } from '../i18n';
import { EulaApiService } from './api/terms';
import { AppRoutes } from './app.routes';
import { LANGUAGES_TRANSLATIONS } from './core/const';
import { State } from './store/store.state';

const reducers: ActionReducerMap<State> = {
  [LAYOUT_REDUCER_STATE_NAME]: layoutReducer,
  [CURRENT_USER_REDUCER_STATE_NAME]: currentUserReducer,
};

const metaReducers: MetaReducer<State>[] = [];
const oktaAuth = new OktaAuth(environment.oktaConfiguration);

const multiLanguageFallback: MultiLanguageFallback = (
  languageCode: LanguageCode,
) => {
  return MESSAGES.general.value.missingTranslation(
    LANGUAGES_TRANSLATIONS[languageCode],
  );
};

registerLocaleData(localeData);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    AuthMeansApiService,
    ChargingSessionsApiService,
    EmspContractsApiService,
    EmspOfferInvitationAcceptanceApiService,
    EmspOfferInvitationApiService,
    EulaApiService,
    LanguageService,
    LogPwaUpdateService,
    NotificationService,
    PromptPwaUpdateService,
    UserAddressesApiService,
    UserApiService,
    WebsocketService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        return () => {};
      },
      deps: [TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: lang },
    { provide: MAT_DATE_LOCALE, useValue: lang },
    provideHttpClient(withInterceptors([apiInterceptor])),
    provideSaveTrap(MESSAGES.app.saveTrap.confirm),
    importProvidersFrom(
      DialogModule,
      RouterModule.forRoot(AppRoutes, {
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
      StoreModule.forRoot(reducers, { metaReducers }),
      EffectsModule.forRoot([
        LayoutEffects,
        CurrentUserEffects,
        StoreRouterEffects,
      ]),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
      StoreRouterConnectingModule.forRoot(),
      OktaAuthModule.forRoot({ oktaAuth }),
      AuthenticationModule.forRoot(environment.noAuth),
      TrackingModule.forRoot(environment.googleTagManagerId),
      ToastrModule.forRoot({
        toastComponent: NotificationComponent,
      }),
      LanguageModule.forRoot(multiLanguageFallback),
    ),
  ],
};
