import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class NotificationsPanelService {
  private openedSubject$ = new ReplaySubject<boolean>(1);

  public opened$ = this.openedSubject$.asObservable();

  public open(): void {
    this.openedSubject$.next(true);
  }

  public close(): void {
    this.openedSubject$.next(false);
  }
}
