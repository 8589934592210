import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { isNotNil } from '@core/is-not-nil';
import { LanguageCode, Nil } from '@model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Action, ActionColor, ActionComponent } from '@ui/action';
import { IconComponent, IconSize } from '@ui/icon';
import { LanguageModule } from '@ui/language';
import { ListItemTagComponent } from '@ui/list-item';
import {
  Notification,
  NotificationsPanelComponent,
} from '@ui/notifications-panel';
import { SelectableItem } from '@ui/selectable-item';
import { ToolbarComponent } from '@ui/toolbar';
import { UiService } from '@ui/ui.service';
import { UserMenuItem } from '@ui/user-menu';
import { isNil, last } from 'lodash-es';
import { NgLetModule } from 'ng-let';

import { TopBarBreadcrumbsComponent } from './breadcrumbs/top-bar-breadcrumbs.component';
import { CurrentLocation, TopBarMessages, TopBarUser } from './top-bar.types';

@UntilDestroy()
@Component({
  selector: 'etn-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ActionComponent,
    CommonModule,
    IconComponent,
    LanguageModule,
    ListItemTagComponent,
    NgLetModule,
    NotificationsPanelComponent,
    ToolbarComponent,
    TopBarBreadcrumbsComponent,
  ],
})
export class TopBarComponent implements OnChanges {
  public constructor(
    private uiService: UiService,
    private router: Router,
  ) {}

  @Input() public currentLanguage: SelectableItem | Nil;
  @Input() public languages: SelectableItem[] | Nil;
  @Input() public location: CurrentLocation | Nil;
  @Input() public messages: TopBarMessages | Nil;
  @Input() public user: TopBarUser | Nil;
  @Input() public hideMenu = false;

  @Output() public backClick = new EventEmitter<void>();
  @Output() public languageChange = new EventEmitter<LanguageCode>();
  @Output() public logout = new EventEmitter<void>();
  @Output() public menuClick = new EventEmitter<void>();

  @HostBinding('class.title-only')
  public titleOnly = false;

  public mobileView = toSignal(this.uiService.mobileView$);
  public smallScreen = toSignal(this.uiService.smallScreen$);

  public accountName: string | Nil;
  public accountActions: UserMenuItem[] = [];
  public notifications: Notification[] = [];

  public backAction: Action | Nil;
  public menuAction: Action = {
    id: 'menu',
    icon: 'menu',
    color: ActionColor.Primary,
    iconSize: IconSize.Default,
    callback: () => {
      this.menuClick.emit();
    },
  };

  public ngOnChanges({ user, messages }: SimpleChanges): void {
    if (isNotNil(user) || isNotNil(messages)) {
      this.accountName = this.getUserDisplayName(this.user);
      this.accountActions = this.getAccountActions(this.user, this.messages);
    }
    if (isNotNil(location)) {
      this.backAction = this.getBackAction(this.location);
    }

    this.titleOnly = this.isTitleOnly(this.location);
  }

  public onLanguageChange(language: SelectableItem): void {
    this.languageChange.emit(language.id as LanguageCode);
  }

  private getAccountActions(
    user: TopBarUser | Nil,
    messages: TopBarMessages | Nil,
  ): UserMenuItem[] {
    if (isNil(user) || isNil(messages)) {
      return [];
    }

    return [
      ...(user.actions ?? []),
      {
        id: 'logout',
        name: messages.button.logout,
        icon: 'power_settings_new',
        callback: () => {
          this.logout.emit();
        },
      },
    ];
  }

  private getUserDisplayName(user: TopBarUser | Nil): string | Nil {
    if (isNil(user)) {
      return undefined;
    }
    return `${user.firstName} ${user.lastName}`;
  }

  private getBackAction(location: CurrentLocation | Nil): Action | Nil {
    if (isNil(location)) {
      return undefined;
    }

    const breadcrumbs = location.breadcrumbs.filter((breadcrumb) => {
      return isNotNil(breadcrumb.routerLink);
    });

    if (breadcrumbs.length <= 1) {
      return undefined;
    }

    const lastBreadcrumb = last(breadcrumbs);

    if (isNil(lastBreadcrumb)) {
      return undefined;
    }

    return {
      id: 'back',
      icon: 'arrow_back',
      color: ActionColor.Primary,
      iconSize: IconSize.Default,
      callback: () => {
        this.backClick.emit();
        if (isNotNil(lastBreadcrumb.routerLink)) {
          this.router.navigateByUrl(lastBreadcrumb.routerLink);
        }
      },
    };
  }

  private isTitleOnly(location: CurrentLocation | Nil): boolean {
    if (isNil(location)) {
      return true;
    }

    return location.breadcrumbs.length === 0 && isNil(location.tags);
  }
}
