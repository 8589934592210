import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { isNotNil } from '@core/is-not-nil';
import * as Sentry from '@sentry/angular-ivy';
import 'hammerjs';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (isNotNil(environment.logging.sentry)) {
  Sentry.init(environment.logging.sentry);
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig);
