import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { Action, ActionColor, ActionComponent } from '@ui/action';
import { IconComponent, IconName } from '@ui/icon';
import { isNil } from 'lodash-es';

import { CurrentLocation } from '../top-bar.types';

@Component({
  selector: 'etn-top-bar-breadcrumbs',
  templateUrl: './top-bar-breadcrumbs.component.html',
  styleUrls: ['./top-bar-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ActionComponent, IconComponent],
})
export class TopBarBreadcrumbsComponent implements OnChanges {
  public constructor(private router: Router) {}

  @Input() public location: CurrentLocation | Nil;

  @Output() public menuClick = new EventEmitter<void>();

  public breadcrumbs: Action[] = [];
  public breadcrumbSeparatorIcon: IconName = 'chevron_right';

  public ngOnChanges(): void {
    this.breadcrumbs = this.getBreadcrumbs(this.location);
  }

  private getBreadcrumbs(location: CurrentLocation | Nil): Action[] {
    if (isNil(location)) {
      return [];
    }

    const breadcrumbs = (location.breadcrumbs || []).map((item) => {
      return {
        ...item,
        color: ActionColor.Primary,
        disabled: isNil(item.routerLink),
        callback: () => {
          if (isNotNil(item.routerLink)) {
            this.router.navigateByUrl(item.routerLink);
          }
        },
      };
    });

    return breadcrumbs;
  }
}
