<app-shell
  etnTheme
  [brand]="brand"
  [favicon]="favicon"
  [sideNavConfig]="sideNavConfig$ | async"
  [theme]="theme"
>
  <router-outlet></router-outlet>
</app-shell>

<!-- SAVE TRAP -->
<etn-save-trap></etn-save-trap>

<!-- COOKIE CONSENT -->
@if (currentUser$ | async; as currentUser) {
  @if (cookiesNotAccepted$ | async) {
    <etn-cookie-consent
      [messages]="cookieConsentMessages"
      [userId]="currentUser.id"
      [consents]="cookieConsents"
      (accept)="onAcceptCookies($event, currentUser)"
    ></etn-cookie-consent>
  }
}
